<template>
    <div class="testing">
        <img class="bannerImg" src="../assets/images/bananr-gongsi.png" alt="">

        <div class="panorama">
            <p class="panoramaTitle">智慧交通解决方案</p>
            <img style="margin-top: 0.5rem;" class="bannerImg" src="../assets/images/solution/banner.png" alt="">
        </div>
        <div class="page_wrap background" :style="{ 'backgroundImage': `url(${ require('@/assets/images/bj.png')})`, 'height':'5rem', 'marginTop':'0.6rem'}">
            <div class="page_flex abstract">
                <div class="panoramaTitle abstract-title" style="marginTop:0.3rem;">方案摘要</div>
                <div class="abstract-content">
                    <div class="abstract-left background" :style="{ 'backgroundImage': `url(${ require('@/assets/images/transport01.png')})`}"></div>
                    <div class="abstract-right background">
                        交通的需求爆发式增长，使得道路的安全管理难度相比于过去大大增加，随着公众开始对安全、便捷出行的服务期望逐渐增长，倒逼着公路运营开始提升其服务意识。然而现有的视频监控设备性能不足，可用性差，一旦发生紧急情况，无法及时做出处置，交通行业迫切需要提升其智能化水平。
                    </div>
                </div>
            </div>
        </div>

        <div class="panoramaTitle" style="marginTop:0.3rem;">解决方案介绍</div>
        <div class="page_column">
            <div class="page_flex" style="margin:0.2rem auto;width:55%">
                <span style="fontSize:0.14rem;lineHeight:0.3rem">
                    <span style="fontSize:0.22rem;color:#333">总体目标：</span>
                    以“ 交通强国 ”战略、数字交通发展规划为总纲，建立智慧告诉架构体系，提高路网效率，降低安全事故，提升出行安全，通过AI技术梳理通行规律，实现
                    <span style="fontSize:0.22rem;color:#196ECE;font-style: italic;"> “ 安全、高效、舒适、优质 ” </span>的出行新体验
                </span>
            </div>
            <div class="page_flex">
                <div class="transItem" v-for="item in transSolutionList" :key="item.index">
                    <div class="transItem-top background" :style="{ 'backgroundImage': `url(${ item.src })`}"></div>
                    <div class="transItem-bottom">
                        <div class="transItem-bottom-dot"></div>
                        <div class="page_column" style="text-align:left">
                            <span class="transItem-bottom-title">{{ item.title }}</span>
                            <span>{{ item.nextTitle }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page_wrap" style="background:#f5f5f5;marginTop:0.6rem">
            <div class="page_flex">
                <div v-for="item in introduceList" :key="item.index">
                    <div class="introduceTop">
                        <div class="introduceTop-left">
                            <div class="item-title">{{ item.title }}</div>
                            <div class="item-nextTitle">{{ item.nextTitle }}</div>
                        </div>
                        <div class="introduceTop-right background" :style="{ 'backgroundImage': `url(${ item.src })`}"></div>
                    </div>
                    <div class="introduceBottom" v-for="info in item.info" :key="info.index">
                        <div class="introduceBottom-title">{{ info.detailTitle }}</div>
                        <div v-for="detail in info.details" :key="detail.index">
                            <span class="rhomb-dot"></span><span style="color:#888">{{ detail }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page_wrap">
            <div class="page_flex background" :style="{ 'backgroundImage': `url(${ require('@/assets/images/transportBg.png') })` , 'height':'6rem'}"></div>
        </div>

        <div class="income">
            <p class="panoramaTitle">客户收益</p>
            <div class="page_wrap" style="margin:0.5rem auto;">
                <div class="page_content" style="maxWidth:15rem">
                    <div v-for="(item, index) in incomeList" :key="index">
                        <div class="newsList-item news-flex">
                            <div class="newsList-item-left background">
                                {{item.num}}
                            </div>
                            <div class="newsList-item-right">
                                <div class="newsItem-title news-flex">
                                    <span style="color: #196ECE;margin-bottom: 0.2rem;">{{ item.name }}</span>
                                </div>
                                <div class="newsItem-content">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="customerCase">
            <p class="panoramaTitle">客户案例</p>
            <div class="page_wrap" style="margin:0.5rem auto;">
                <div class="page_content" style="maxWidth:15rem">
                    <div v-for="(item, index) in caseList" :key="index">
                        <div class="newsList-item news-flex">
                            <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">
                            </div>
                            <div class="newsList-item-right">
                                <div class="newsItem-title news-flex">
                                    <span>{{ item.name }}</span>
                                </div>
                                <div class="newsItem-content">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="partner">
            <p class="panoramaTitle">合作伙伴</p>
            <p class="partnerTitle">我们将与众多同行者一起共建人工智能产业生态</p>
            <div class="partnerList">
                <img v-for="(item, index) in imglist" :key="index"  :src="item" style="margin: 0 0.5rem;">
            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */
    import newsData from "@/assets/js/news";

    export default {
        name: 'solution',
        data () {
            return {
                introduceList:[
                    {
                        src:require('@/assets/images/transport/transport06.png'),
                        title:'动态信息采集、监测、预警',
                        nextTitle:'——  自动识别各类交通事件，提高事件的主动发现能力',
                        info:[
                            {
                                detailTitle:'AI视频分析技术 —— 感知功能',
                                details:[
                                    '实时采集道路状态（流量、速度、占有率）','主动发现异常事件 (拥堵、事故、停车、逆行、行人、抛洒物、团雾)', '主动识别重点车辆 (车牌、车型、危险品标志)'
                                ]
                            },
                            {
                                detailTitle:'车辆信息平台对接',
                                details:[
                                    '两客一危、稽查黑名单、入口治超'
                                ]
                            },
                        ]
                    },
                    {
                        src:require('@/assets/images/transport/transport07.png'),
                        title:'提升道路日常管控监测能力',
                        nextTitle:'——  强化对重点车辆的管控能力，保障道路运行安全、有序',
                        info:[
                            {
                                detailTitle:'综合信息动态监管—平台功能',
                                details:[
                                    '全路段路径监管道路使用情况（客货），潮汐周期','车辆行驶轨迹（异常驾驶行为）', '路段平均车速（客货）', '收费站（服务区）排队长度及趋势预警', '时间黑点分布', '团雾预警', '运维辅助'
                                ]
                            },
                        ]
                    },

                ],
                transSolutionList:[
                    { src:require('@/assets/images/transport/transport02.png'), title:'智慧设施', nextTitle:'打造 “ 更安全 ” 公路'},
                    { src:require('@/assets/images/transport/transport03.png'), title:'智慧决策', nextTitle:'打造 “ 更高效 ” 公路'},
                    { src:require('@/assets/images/transport/transport04.png'), title:'智慧管控', nextTitle:'打造 “ 更可控 ” 公路'},
                    { src:require('@/assets/images/transport/transport05.png'), title:'智慧服务', nextTitle:'打造 “ 更智能 ” 公路'},
                ],
                algoList:[
                    { label:'入侵检测', url:'' },
                    { label:'姿态估计', url:'' },
                    { label:'钢筋计数', url:'' },
                    { label:'安全帽检测', url:'' },
                    { label:'车牌识别', url:'' },
                    { label:'火焰检测', url:'' },
                    { label:'翻墙检测', url:'' },
                    { label:'口罩检测', url:'' },
                ],
                photoList: [{name: '智算云平台',
                    src: require('@/assets/images/industry2.png'),
                    content: '中科逆熵工业视觉检测以打造智慧企业为目标，以多种人工智能算法和打数据处理为手段，依托“云边一体”的架构基础，结合人工智能、数字孪生等新一代信息技术，自上而下打通战略决策层到现场执行层的各个系统，提高生产效率，实现个性化定制、网络化协同、智能化制造、服务化延伸的新型制造管理模式，为制造企业数字化转型升级赋能。',
                },],
                caseList: [{name: '中国电信北京研究院',
                    src: require('@/assets/images/solution/telecom.png'),
                    content: '本次智能车路协同算法开发项目，利用深度学习来提取视频中的道路、车辆、行人等信息并结合大数据加以分析，快速智能地完成交通事件检测及违规车辆识别，可以有效地减轻执法人员的负担，提高审核效率，降低成本。以寒武纪为代表的国产智能芯片，为上述思路提供了自主可控的智能硬件基础。'
                }],
                incomeList: [{name: '数据价值挖掘',
                    num: '01',
                    content: '整合公路、高速、城市道路等交通行业多源信息资源，对数据进行检测、清洗、分析、挖掘等治理，将数据转化为资产，提高数据利用价值'
                },{name: '智能模型',
                    num: '02',
                    content: '针对不同的交通应用场景，提供多维智能模型，结合人工智能技术，通过对多元数据的融合分析和深度挖掘，实现交通组织优化、重点车辆监管、智能诱导、交通事件感知、交通态势评价等服务。'
                },{name: '辅助决策',
                    num: '03',
                    content: '基于大数据分析和人工智能技术，通过数据可视化的直观方式，实现交通态势的总览总控，为领导决策提供全面、准确、及时、可靠的信息支撑，提高决策的科学水平，提升交通运行效率。'
                }],
                productChildren: [],
                imglist:[
                    require('@/assets/images/partner/1.png'),require('@/assets/images/partner/2.png'),require('@/assets/images/partner/3.png'),
                    require('@/assets/images/partner/4.png'),require('@/assets/images/partner/5.png'),require('@/assets/images/partner/6.png'),
                    require('@/assets/images/partner/7.png'),require('@/assets/images/partner/8.png'),require('@/assets/images/partner/9.png'),
                    require('@/assets/images/partner/10.png'),require('@/assets/images/partner/11.png'),require('@/assets/images/partner/12.png'),
                    require('@/assets/images/partner/13.png'),require('@/assets/images/partner/14.png'),require('@/assets/images/partner/15.png'),
                    require('@/assets/images/partner/16.png'),require('@/assets/images/partner/17.png'),require('@/assets/images/partner/18.png'),
                    require('@/assets/images/partner/19.png'),require('@/assets/images/partner/20.png'),require('@/assets/images/partner/21.png'),
                ],
            }
        },
        mounted () {
            this.productChildren = newsData.solutionList
            document.body.scrollTop = document.documentElement.scrollTop = 0
        },
        methods: {
            toRoute (val) {
                this.$router.push(val)
            }
        }
    }
</script>
<style lang="scss" scoped>
.introduceBottom{
    text-align: left;
    line-height: 0.32rem;
    margin-bottom:0.24rem;
    &-title{
        font-size: 0.18rem;
        color:#333;
        font-weight: 600;
        margin-top: 0.24rem;
    }
}
.rhomb-dot{
    width: 0.10rem;
    height: 0.10rem;
    transform: rotateZ(45deg)skew(2deg,2deg);
    background: royalblue;
    display: inline-block;
    margin-right:0.1rem;
}
.introduceTop{
    margin:0.24rem 0;
    display: flex;
    justify-content: space-between;
    &-left{
        background: #fff;
        padding:0.3rem;
        .item-title{
            font-size: 0.2rem;
            font-weight: 600;
            color:#333;
            text-align: left;
            line-height: 0.4rem;
        }
        .item-nextTitle{
            color:#606266;
            font-size: 0.14rem;
            width: 70%;
            float: right;
            font-weight: 500;
            text-align: right;
            margin-top: 0.2rem;
        }
    }
    &-right{
        width: 3rem;
        height: 2rem
    }
}
.transItem{
    width: 3rem;
    height: 2.5rem;
}
.transItem-top{
    width: 3rem;
    height:2rem;
}
.transItem-bottom{
    display: flex;
    justify-content: space-between;
    margin: 0.2rem 0;
    &-dot{
        width: 0.3rem;
        height: 1px;
        border: 1px solid #0061E3;
        background: #0061E3;
        margin-top:0.1rem;
        margin-right:0.1rem;
    }
    &-title{
        font-size: 0.18rem;
        height: 0.3rem;
        line-height: 0.2rem;
        font-weight: 600;
    }
}
.abstract{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.abstract-content{
    width: 90%;
    height: 90%;
    padding:0.24rem 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.abstract-title{
    height: 0.38rem;
    margin:0 auto;
}
.abstract-left, .abstract-right{
    width: 45%;
    height: 95%;
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.abstract .abstract-right{
    width: calc(45% - 1rem);
    height: calc(95% - 1rem);
    padding:0.5rem;
    font-size: 0.14rem;
    color: #333;
    line-height: 0.3rem;
    text-align: left;
}

    .background-bottom{
        position:absolute;
        width: 100%;
        bottom:0;
        left:0;
        background: #ffffff7c;
        height:0.5rem;
    }
    .background-bottom-content{
        width: 70%;
        height: 0.5rem;
        line-height: 0.5rem;
        display: flex;
        justify-content: space-between;
        margin:0 auto;
        &-item{
            width: 0.8rem;
            height: 0.5rem;
            color:#606266;
            cursor: pointer;
            font-size: 0.14rem;
        }
        &-item:hover{
            color:#0061E3;
            border-bottom:1px solid #0061E3;
        }
    }
    .testing {
    @keyframes partnerAnima
    {
        0%   {right: 0}
        50%  {right: 30rem}
        100%  {right: 60rem}
    }
    .partnerList {
        position: relative;
        animation: partnerAnima 100s linear 1 alternate;
    }
    width: 100%;
    height: 100%;
    text-align: center;
    .detail {
        background: #F7F7F7;
        width: 80%;
        margin: 0.5rem auto;
        padding: 0.2rem;
        .text {
            text-align: left;
            width: 60%;
            margin: 0.2rem auto;
            color: #333333;
            font-size: 0.14rem;
        }
    }
    .partner {
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
    .partnerList {
        height: 1.2rem;
        width: 61rem;
    img {
        height: 1.2rem;
        float: left;
    }
    }
    .partnerTitle {
        color: #999999;
        font-size: 0.16rem;
        margin: 0.2rem 0;
    }
    }
    .panoramaTitle {
        text-align: center;
        font-size: 0.28rem;
        margin-top: 0.6rem;
        // background-color: #012961;
        color: #333;
        border-radius: 0.2rem;
        display: inline-block;
        padding: 0.1rem 0.2rem;
    }
    .panorama {
        text-align: center;
        width: 80%;
        margin: 0 auto;
    .productTab {
        width:100%;
        height: 4.5rem;
        margin: 0.5rem auto auto auto;
    .productChildren {
    //margin-bottom: 0.4rem;
        height: 3rem;
    .left, .right {
        display: inline-block;
        width: 20%;
        height: 100%;
        background-color: #F7F7F7;
        border-top: 2px solid #0061E3;
        text-align: left;
        position: relative;
        box-shadow: 0px 3px 8px 0px rgba(187, 187, 187, 0.3);
    .text {
        display: inline-block;
        width: 90%;
        height: 100%;
        overflow: hidden;
        padding: 0.2rem;
    div {
        padding: 0.2rem;
        font-size: 0.16rem;
    span {
        padding: 0.1rem;
        cursor: pointer;
    }
    .active {
        border-bottom: 1px solid #0061E3;
        color: #0061E3;
    }
    }
    }
    }
    .right {
        margin-left: 5%;
    }
    }
    .application {
        height: 4rem;
        overflow: hidden;
    }
    .tabs {
        margin-bottom: 0.4rem;
    span {
        display: inline-block;
        width: 20%;
        height: 0.64rem;
        border-radius: 0.03rem;
        background: #F7F7F7;
        cursor: pointer;
        color: #0061E3;
        line-height: 0.6rem;
        font-size: 0.24rem;
    }
    .active {
        background: #0061E3;
        color: #FFFFFF;
    }
    }
    }
    }
    }
    .income {
        width: 70%;
        margin: 0 auto;
        .newsList-item {
            height: 1rem;
            &-left{
                 width:2.4rem;
                 height: 1rem;
                 color: #196ECE;
                 font-weight: 700;
                 font-size: 0.32rem;
                line-height: 1rem;
                text-align: center;
                border-right: 2px solid #196ECE;
             }
            &-right {
                 width: calc(100% - 2.8rem);
             }
            }
        .newsItem-content {
            height: 0.5rem;
        }
    }
    .customerCase {
        .background {
            background-size: contain;
        }
        .newsList-item {
        &-left{
             width:4.4rem;
             height: 2.8rem;
         }
        &-right {
             width: calc(100% - 4.8rem);
         }
        }
    }
    .news-flex{
        display: flex;
        justify-content: space-between;
    }
    .newsList-item{
        height: 3rem;
        padding:0.2rem 0.1rem;
        border-top:1px solid #dedede;
        border-bottom:1px solid #dedede;
        text-align: left;
    &-left{
         width:7.4rem;
         height: 2.8rem;
     }
    &-right{
         width: calc(100% - 7.8rem);
         display: flex;
         justify-content: space-around;
         flex-direction: column;
    .newsItem-title{
        text-align: left;
        font-size: 0.22rem;
        color:#333;
    }
    }
    .newsItem-content{
        font-size: 0.13rem;
        color:#606266;
        line-height: 0.26rem;
        height:1.5rem;
        width:100%;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient:vertical;
        /* autoprefixer: on */
        -webkit-line-clamp:6;

    }
    .newsItem-link{
        width: 100%;
        height:0.4rem;
    &-button{
         width: 0.3rem;
         height:0.3rem;
         float: right;
     }
    }

    }

    .newsList-item:hover {
        background: #f0f6ff;
        cursor: pointer;
    }

</style>
